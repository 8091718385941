import getLanguage from "_/get-lang";

export default class AppStore {
    language = "";

    languageData = {};

    loadLanguageData = async (lang) => {
        this.language = getLanguage(lang);
        try {
            const data = await import(`_/data/${this.language}.json`);
            Object.assign(this.languageData, data);
        }
        catch (err) {
            throw new Error("Error loading language data:");
        }
    };
}
