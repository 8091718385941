import React from "react";

function PentagonModule() {
    return (
        <>
            {/* pentagon */}
            <div className="mil-dodecahedron">
                <div className="mil-pentagon">
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
                <div className="mil-pentagon">
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
                <div className="mil-pentagon">
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
                <div className="mil-pentagon">
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
                <div className="mil-pentagon">
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
                <div className="mil-pentagon">
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
                <div className="mil-pentagon">
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
                <div className="mil-pentagon">
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
                <div className="mil-pentagon">
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
                <div className="mil-pentagon">
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
                <div className="mil-pentagon">
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
                <div className="mil-pentagon">
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
            </div>
            {/* pentagon */}
        </>
    );
}

export default PentagonModule;
