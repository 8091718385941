import React from "react";
import Hero from "./Hero";
import About from "./About";
import Activities from "./Activities";
import Testimonial from "./Testimonial";

function Home() {
    return (
        <>
            <Hero />
            <About />
            <Activities />
            <Testimonial />
        </>
    );
}

export default Home;
