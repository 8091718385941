import React from "react";
import "./ErrorFallback.css";

function ErrorFallback() {
    const handleContinue = () => {
        window.location.href = "/";
    };

    return (
        <div className="error-fallback">
            <h2>oh snap, something went wrong.</h2>
            <p className="subheading">Well, this is unexpected...</p>
            <div className="spacer" />
            <p>An error has occurred and the administrators have been notified.</p>
            <div className="spacer-small" />
            <button type="button" onClick={handleContinue}>Continue</button>
        </div>
    );
}

export default ErrorFallback;
