if (window.location.hostname === "www.arisingages.org") {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-B8DF43EYEF";
    script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        const gtag = (...args) => window.dataLayer.push(args);
        gtag("js", new Date());
        gtag("config", "G-B8DF43EYEF");
    };
    document.head.appendChild(script);
}
