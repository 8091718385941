import React, { Suspense, lazy } from "react";
import { Observer } from "mobx-react-lite";
import { RouteNodeComponent } from "mr-router5";
import Header from "_/components/Layout/Header";
import Footer from "_/components/Layout/Footer";
import ErrorFallback from "_/components/Layout/ErrorFallback";

const routeName = "";

const SentryErrorBoundary = lazy(() => import("@sentry/react").then((Sentry) => ({ default: Sentry.ErrorBoundary })));

function MainNode() {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <SentryErrorBoundary fallback={<ErrorFallback />} showDialog>
                <div className="mil-wrapper" id="top">
                    {/* <Cursor /> */}

                    {/* <Preloader /> */}

                    <div className="mil-progress-track">
                        <div className="mil-progress" />
                    </div>

                    {/* {!noHeader && (
                        <Header
                            layout={header}
                            extraclassName={extraClass}
                        />
                    )} */}
                    <Header />

                    <div className="mil-content">
                        <div id="swupMain" className="mil-main-transition">
                            <Observer>
                                {() => <RouteNodeComponent routeNodeName={routeName} />}
                            </Observer>

                            {/* {!noFooter && <Footer layout={footer} />} */}
                            <Footer />
                        </div>
                    </div>
                </div>
            </SentryErrorBoundary>
        </Suspense>
    );
}

export default MainNode;
